@import "~react-datepicker/dist/react-datepicker.css";
@import "./variables";

.report{
  hr{
    border-top: 1px solid $copec-another-grey;
    border-bottom: none;
    border-left: none;
    border-right: none;
    margin: 0;
  }

  .cards-container{
    width: 100%;
    display: inline-block;
  }

  .report-card{
    width: 304px;
    height: 72px;
    box-shadow: 0 0 4px 0 rgba(14, 105, 175, 0.3);
    border-radius: 6px;
    margin: 27px 28px 32px 3px;
    position: relative;
    float: left;
    .left-side{
      position: absolute;
      top: 0;
      left: 0;
      width: 38px;
      padding: 16px 12px;
    }
    .right-side{
      position: absolute;
      top: 0;
      right: 0;
      width: 212px;
      padding: 16px 16px 16px 10px;
      .top{
        border-bottom: 1px solid $copec-another-grey;
        font-size: 12px !important;
        padding-bottom: 4px;
        color: $copec-dark-blue;
      }
      .bottom{
        font-size: 14px !important;
        padding-top: 5px;
      }
    }

    &.blue{
      .bottom{
        color: $copec-dark-blue;
      }
    }

    &.yellow{
      .bottom{
        color: $copec-yellow;
      }
    }
    &.green{
      .bottom{
        color: $copec-green;
      }
    }
    &.red{
      .bottom{
        color: $copec-red;
      }
    }
    &.last{
      margin-right: 0;
      margin-left: 1px;
    }
  }

  .filters-container{
    margin-top: 32px;
    display: inline-block;
  }

  .tables-container{
    width: 100%;
    position: relative;
    //display: inline-block;
    .table-title{
      font-weight: bold;
      font-size: 12px;
      margin-bottom: 8px;
      text-align: left;
    }
    .table-left{
      float: left;
      tr{
        cursor: auto;
      }
    }
    .table-right{
      float: right;
    }
    tr:hover{
      font-weight: normal;
    }

    .report-link, .report-no-link{
      padding-top: 4px;
      width: 24px;
      height: 20px;
      border-radius: 12px;
      text-align: center;
    }
    .report-link{
      text-decoration: underline;
      cursor: pointer;
      &:hover, &.active{
        color: white;
        background: $copec-dark-blue;
      }
    }
  }

  .tabla-copec-1{
    width: 520px;
  }

  table{
    margin-top: 0;
  }

  .bottom-space{
    height: 20px;
    width: 100%;
    display: inline-block;
  }

  .th-90{
    width: 90px;
    .double-line{
      width: 90px;
    }
  }

  .th-100{
    width: 100px;
    .double-line{
      width: 100px;
    }
  }

  .th-110{
    width: 110px;
    .double-line{
      width: 110px;
    }
  }

  .th-120{
    width: 120px;
    .double-line{
      width: 120px;
    }
  }

  .th-127{
    width: 127px;
    .double-line{
      width: 127px;
    }
  }

  .th-170{
    width: 170px;
    .triple-line{
      width: 170px;
    }
  }

  .th-252{
    width: 252px;
    .th-data{
      width: 252px;
    }
  }

  .th-300{
    width: 300px;
  }

  .th-data{
    min-width: 50px !important;
    &.double-line{
      height: 33px;
      padding: 7px 3px 6px;
      text-align: center;
    }

    &.triple-line{
      height: 43px;
      padding: 7px 3px 6px;
      text-align: center;
    }

  }
}

.table-row{
  background: white;
  td{
    padding: 8px;
    color: $copec-dark-blue;
  }
  .first-column{
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  .last-column{
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}
.table-row-separator{
  height: 8px;
  background: $copec-another-grey;
}

.report-postulantes-datepicker{
  input{
    padding: 11px 10px 10px;
    width: 170px;
    border-radius: 4px;
    border: 1px solid $copec-light-grey;
    color: $copec-dark-blue;
    font-size: 12px;
    font-weight: 600;
    &::-webkit-input-placeholder{
      color: $copec-light-grey;

    }
  }
}

.report-postulantes-table{
  width: 1312px;
  overflow-x: auto;
}


.table-title-report-postulantes{
  height: 45px;
  margin-bottom: 10px;
  .title-text{
    float: left;
    margin-top: 25px;
  }
  .export-excel{
    float: right;
    cursor: pointer;
  }
}

