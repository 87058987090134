@import "./variables";
.carton{
	margin-top: 30px;
	.section{
		color: $copec-dark-blue;
		margin-bottom: 16px;
		width: 100%;
		display: inline-block;
		.section-title{
			color: $copec-light-grey;
			font-weight: bold;
			font-size: 12px;
			margin-bottom: 16px;
			width: 100%; 
			border-bottom: 2px solid $copec-another-grey; 
			line-height: 0.1em;
		  margin: 10px 0 20px; 
		  span { 
	    	background: white; 
	    	padding: 0 10px 0 0; 
			}
		}

		.carton-section-subtitle{
			color: $copec-dark-blue;
			font-size: 16px;
			font-weight: bold;
			display: inline-block;
			width: 100%;
			margin: 16px 0;
		}

		.section-data{
			font-size: 12px;
			float: left;
			margin-right: 31px;
			margin-bottom: 16px;
			&.section-data-96{
				width: 96px;
				.title{
					width: 130%;
				}
			}
			&.section-data-190{
				width: 190px;
			}
			&.section-data-192{
				width: 192px;
			}
			&.section-data-170{
				width: 170px;
			}
			&.section-data-260{
				width: 260px;
			}
			&.section-data-416{
				width: 416px;
			}
			&.section-data-462{
				width: 462px;
			}
			&.section-data-640{
				width: 640px;
			}
			&.section-data-full{
				width: 100%;
			}
			&.section-data-last{
				margin-right: 0;
			}
			.title{
				width: 100%;
				font-size: 12px;
				margin-bottom: 8px;
			}
			.data{
				background: $copec-input-grey;
				padding: 8px 16px;
				border-radius: 4px;
				min-height: 15px;
			}
			.data-input{
				padding: 7px 16px;
				border: 1px solid $copec-light-grey;
				border-radius: 4px;
				input{
					padding: 0;
					border: none;
					font-size: 12px;
					color: $copec-dark-blue;
					width: 100%;
				}
			}
		}
	}

	.carton-table{
		width: 100%;
		border: none;
		th{
			background: $copec-dark-blue;
			color: white;
			height: 46px;
			border-color: $copec-dark-blue;
		}
	}

	.tabla-copec-1-input-text{
		padding: 16px;

		input{
			padding: 6px 8px 6px 16px;
			border: 1px solid $copec-grey-3;
		}
		.tabla-copec-1-input-text-socio, .tabla-copec-1-input-text-gasolinas{
			width: 300px;
		}
		.tabla-copec-1-input-text-rut{
			width: 94px;
		}
		.tabla-copec-1-input-text-profesion{
			width: 165px;
		}
		.tabla-copec-1-input-text-sociedad{
			width: 41px;
		}
	}

	.table-copec-1-editing{
		td:first-child{
			.tabla-copec-1-input-text{
				padding-left: 24px;
			}
		}
		td:last-child{
			.tabla-copec-1-input-text{
				padding-right: 24px;
			}
		}
	}
}