@import "~react-datepicker/dist/react-datepicker.css";
@import "./variables";

h1{
  text-align: center;
  color: $copec-dark-blue;
  font-size: 30px;
}

.view-title{
	color: $copec-dark-blue;
	font-size: 24px;
}

.title-hr{
	width: 160px;
	display: inline-block;
	border: none;
	border-top: 1px solid $copec-another-grey;
}

.ended-process{
	color: $copec-dark-blue;
	font-size: 20px;
	font-weight: bold;
}

.back-button{
	color: $copec-dark-blue;
	font-weight: bold;
	background: transparent;
	border: none;
	font-size: 14px;
	cursor: pointer;
	float:  right;
}

.applicant-photo{
	width: 200px;
}

.applicant-columns {
  display: flex;
  width: 100%;
}
.col-left {
  padding-top: 16px;
  width: 358px;
}

.editar-icon{
	float: right;
	width: 12px;
	cursor: pointer;
}

.sidebar-text-input{
	padding: 7px 15px !important;
	width: 162px !important;
	color: $copec-dark-blue !important;
	margin-top: 8px !important;
	font-size: 12px !important;
	border-radius: 4px !important;
}

.sidebar-actions-container{
	margin-left: -5px;
	overflow: visible;
	button{
		padding: 8px 0;
		width: 100px;
		margin: 10px 5px;
	}
}

.col-right{
	width: 962px;
	padding-top: 16px;
}

.data-title, .data-title-dynamic{
	font-weight: bold;
	color: $copec-dark-blue;
	margin-top: 15px;
}

.data-title-dynamic{
	font-size: 12px;
	text-transform: uppercase;
	margin-top: 16px;
	&.required::after{
	  content: "*";
 		color: $copec-red;
	}
}

.grade-info-title{
	font-size: 12px;
	font-weight: normal;
	text-transform: lowercase;
}

.concesionario-change-title{
	font-weight: bold;
	color: $copec-red;
	margin-top: 15px;
}

.data-subtitle-dynamic{
	font-size: 14px;
	color: $copec-grey;
}

.checkbox-input-container{
	margin-top: 30px;
	.data-title-dynamic{
		margin: 0;
	}
	input[type=checkbox]{
		float: left;
		margin-right: 10px;
		margin-top: 2px;
	}
}

.data-info{
	background: $copec-input-grey;
	font-size: 12px;
	color: $copec-light-grey;
	padding: 8px 16px;
	border-radius: 4px;
	width: 162px;
	margin: 8px 0 24px;
	.data-info-email{
		overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 145px;
    display: inline-block;
	}
	&.data-info-residencia{
		margin-bottom: 10px;
		&.data-info-residencia-last{
			margin-bottom: 24px !important;
		}
	}
}

.download-file-button{
	border: 1px solid $copec-dark-blue;
	border-radius: 24px;
	background: white;
	padding: 7px 20px;
	color: $copec-dark-blue;
	width: 192px;
	margin: 20px 0;
	cursor: pointer;
	font-size: 12px;
	font-weight: bold;
	&.historial{
		width: 300px;
		margin-right: 20px;
	}
}

.choices{
	float: right;
	button{
		margin-left: 34px;
	}
}

.step-choices{
	text-align: center;
	button{
		margin: 40px 30px;
	}
}

.comment-button{
	float: right;
	margin-top: 12px;
}

.choice-button{
	float: left;
}

.upload-file-button, .text-input{
	margin-top: 10px;
	width: 400px;
}

.action-buttons-container{
	display: inline-block;
}

.save-changes{
	float: left;
}

.clear-button{
	margin-right: 34px;
}

.comments{
	background: $copec-input-grey;
	margin: 25px 0 50px;
	padding: 32px 24px;
	border-radius: 4px;
	.comments-title{
		color: $copec-dark-blue;
		font-size: 12px;
		font-weight: bold;
		margin-bottom: 5px;
	}
	textarea{
		border: 1px solid $copec-light-grey;
		padding: 8px 16px;
		color: $copec-dark-blue;
		width: calc(100% - 28px);
	}
}

.comments-container{
	margin-top: 90px;
	&__title{
		color: $copec-dark-blue;
		font-weight: bold;
		font-size: 12px;
		margin-bottom: 8px;
	}
	&__comment{
		background: $copec-another-grey;
		color: $copec-dark-blue;
		position: relative;
		margin-bottom: 8px;
		padding: 10px 16px;
		border-radius: 4px;
	}
	&__name{
		font-size: 14px;
		font-weight: bold;
	}
	&__text{
		font-size: 12px;
		font-weight: normal;
		margin-top: 10px;
		max-width: 700px;
	}
	&__date{
		position: absolute;
		right: 16px;
		top: 10px;
		font-size: 12px;
		font-weight: bold;
	}
	&__time{
		position: absolute;
		right: 16px;
		top: 30px;
		font-size: 10px;
		font-weight: 500;
	}
}

.comments-table{
	width: 100%;
	border-collapse: collapse;
	tr{
		border-bottom: 8px solid $copec-input-grey;
	}
	td{
		padding: 5px 0; 
		background: $copec-another-grey;
	}
	.name{
		font-weight: bold;
		padding-right: 30px;
		white-space: nowrap;
	}
}

.delete-comment-button{
	margin-top: 15px;
}

.start-process{
	font-weight: bold;

}

.title{
	font-size: 20px;
	color: $copec-dark-blue;
	font-weight: bold;
}

.select-content{
	width: 100%;
	height: 42px;
	border-bottom: 2px solid $copec-another-grey;
	position: relative;
	margin-bottom: 52px;
	.selected-tab{
		border: 3px solid $copec-red;
		border-radius: 3px;
    margin: 0 8px;
    position: absolute;
    width: calc(34% - 16px);
    top: 40px;
    &.selected-tab-step{
    	left: 0;
    }
    &.selected-tab-expediente{
    	left: calc(33% - 8px);
    }
    &.selected-tab-historial{
    	left: calc(66% - 8px);
    }
	}

	button{
		width: calc(33% - 16px);
		background-color: white;
		padding: 10px;
		margin: 0 8px 30px;
		font-weight: bold;
		font-size: 12px;
		cursor: pointer;
		border: none;
		color: $copec-light-grey;
		&.selected{
			color: $copec-dark-blue;
		}
	}
}

.historial-entry{
	margin-bottom: 20px;
	color: $copec-dark-blue;
}

.step-form{
	display: block;
	margin-top: -30px;
	color: $copec-dark-blue;
	font-size: 12px;
	input[type=text], input[type=number], textarea{
		font-size: 12px;
		padding: 8px 16px 7px;
		color: $copec-dark-blue;
		border: 1px solid $copec-light-grey;
		border-radius: 5px;
		margin: 8px 0 0;
	}

	input[type=text], input[type=number]{
		width: 159px;
	}

	textarea{
		width: calc(100% - 32px);
	}
}

.download-file-button-step{
	border: none;
	background: none;
	color: $copec-dark-blue;
	font-weight: bold;
	margin-left: 10px;
	cursor: pointer;
}


.cartas-compromiso-table2{
	table-layout: fixed;
	border-collapse: collapse;
	margin-top: 10px;

	th, td{
		max-width: 128px;
		padding: 0;
	}

	tbody{
		tr{
			background-color: white !important;
			td{
				border-bottom: 1px solid $copec-another-grey;
			}

		}
	}
	
	input{
		width: 78px !important;
		border-radius: 0;
		border: none;
		padding: 8px 10px;
	}
	

	.fuel-total{
		padding: 10px;
		font-weight: bold;
	}

	&.capital-trabajo{
		width: 350px !important;
	}
}

.cartas-compromiso-table{
	table-layout: fixed;
	border: 1px solid $copec-dark-blue;
	border-collapse: collapse;
	margin-top: 10px;

	th:first-child {
    border-left: 1px solid $copec-dark-blue;
  }

  th:last-child {
    border-right: 1px solid $copec-dark-blue;
  }
	
	input{
		width: 105px;
		border-radius: 0;
		border: none;
		padding: 10px;
	}
	th, td{
		padding: 0;
		width: 57px !important;
		overflow: hidden;
		text-align: center;
		border: 1px solid $copec-dark-blue;
		border-collapse: collapse;
	}
	th{
		padding: 10px;
		background-color: $copec-dark-blue;
		border: 1px solid white;
		border-top: 1px solid $copec-dark-blue;
		color: white;
	}

	.fuel-total{
		padding: 10px;
		font-weight: bold;
	}

	&.capital-trabajo{
		width: 350px !important;
	}
}

.missing-field{
	color: $copec-red;
	font-size: 10px;
	display: none;
}

.step-summary{
	margin-bottom: 24px;
}

.combustibles-table{
	margin-top: 10px;
	input{
		max-width: 64px;
	}
}

.capital-trabajo-table{
	margin-top: 10px;
	width: 160px !important;
	th{
		padding: 16px 0 !important;
		text-align: center !important;
	}
	.capital-trabajo-table-td{
		text-align: center;
		padding: 10px;
	}
	input{
		width: 98px !important;
		padding: 8px !important;
	}
}