@import "./variables";

.step-info{
	margin-top: 20px;
	position: relative;
	.expediente-icon{
		position: absolute;
		top: 0;
		left: 0;
	}
	.expediente-date{
		position: absolute;
		top: 0;
		right: 0;
		font-size: 12px;
		font-weight: bold;
		color: $copec-dark-blue;
	}
	.expediente-content{
		border-bottom: 1px solid $copec-another-grey;
		font-size: 12px;
		color: $copec-dark-blue;
		margin-left: 75px;
		min-height: 50px;
		padding-bottom: 16px;
		&-title{
			font-size: 14px;
		}
		&-info{
			line-height: 2;
		}
	}
}

.download-file-button-expediente, .bajar-carton-button{
	color: $copec-blue-2;
	background: none;
	border: none;
	text-decoration: underline;
	font-size: 12px;
	font-weight: normal;
	cursor: pointer;
	display: block;
	padding: 5px 0 0 0;
}
.bajar-carton-button{
	font-size: 14px;
	margin: 20px 0;
}

.expediente{
	h1{
		margin: 0;
	}

	.section{
		margin-top: 16px;
	}
}