@import './variables';

.columns{
	min-height: 700px;
}

.form-columns{
	letter-spacing: 1px;
	margin-top: 30px;
	display: flex;
	width: 100%;

	.col{
		flex: 1;
		width: calc(50% - 10px);
		&:first-child {
  		padding-right: 10px;
		}
		&:last-child {
  		padding-left: 10px;
		}
		&.col-no-padding{
			padding: 0 !important;
		}
	}

	.smaller-font{
		font-size: 12px;
	}
}

.label{
	color: $copec-dark-blue;
	font-weight: bold;
	margin-bottom: 10px;
}

input[type=text], textarea{
	padding: 15px 20px;
	border: 1px solid #cccccc;
	border-radius: 5px;
	width: calc(100% - 42px);
	font-size: 15px;
	color: #222222;
}

.residencia-input{
	margin-bottom: 15px;
	width: calc(100% - 82px) !important;
}

.remove-residencia{
	color: $copec-dark-blue;
	font-weight: bold;
	font-size: 20px;
	border: none;
	background: white;
	cursor: pointer;
}

select{
	padding: 15px;
	border: 1px solid #cccccc;
	border-radius: 5px;
	margin-right: 10px;
	&:last-child{
		margin-right: 0;
	}
}

.upload-file-button{
	border: 1px dashed $copec-dark-blue;
	background: $copec-background-grey;
	padding: 15px 20px;
	text-decoration: underline;
	color: $copec-dark-blue;
	width: 100% !important;
}

.radio-buttons-container{
	margin: 0 0 40px;
	.radio{
		margin-right: 40px;
	}
}

.hidden-file-input{
	display: none;
}

.location{
	width: 302px;
}

.sent-postulation-message{
	margin: 100px auto;
	text-align: center;
	.check{
		background: #57bb25;
		border-radius: 45px;
		width: 45px;
		height: 45px;
		content: url("../assets/img/check.png");
		padding: 20px;
		margin: 0 auto;
	}
	.message{
		color: $copec-dark-blue;
		font-weight: bold;
		font-size: 20px;
		margin: 30px auto 20px;
		.line{
			margin-top: 3px;
			display: block;
		}
	}
	.back-button{
		color: white;
		font-size: 18px;
		font-weight: bold;
		background-color: $copec-dark-blue;
		border-radius: 20px;
		padding: 10px 30px;
		border: none;
		margin-top: 20px;
		float: none;
	}
}

.referred-input-container{
	color: $copec-dark-blue;
	width: 400px;
}
