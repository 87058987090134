.historial{
	.step-name{
		font-size: 16px;
		font-weight: bold;
	}
	.step-info{
		margin: 5px 15px;
		font-size: 14px;
		.total-step-time{
			font-weight: bold;
		}
	}
}