$copec-background-grey: #f0f5f8;
$copec-input-grey: #f1f5f8;
$copec-dark-blue: #003965;
$copec-dark-grey: #dde3e9;
$copec-very-dark-grey: #a7a7a7;
$selected-table: #226085;
$copec-light-grey: #7c97ab;
$copec-red: #ea212e;
$copec-grey: #7b96ab;
$copec-grey-2: #bdbdbd;
$copec-another-grey: #dee5ea;
$copec-blue-2: #1080d5;
$copec-grey-3: #9db1c0;
$copec-green: #07d188;
$copec-yellow: #ffb700;
$copec-green: #009b63;
