@import "./variables";
.react-responsive-modal- {
  &overlay {
    background: rgba(0, 0, 0, 0.5);

    /* Esconder scrollbar */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar { /* Chrome, Safari, Opera */
      display: none;
    }

  }
  &modal {
    padding: 20px;
    padding-bottom: 10px;
    width: 312px;
    border-radius: 9px;

    .radio-buttons-container{
      color: $copec-dark-blue;
      font-size: 15px;
      max-height: 300px;
      overflow: scroll;
      .radio{
        margin-right: 0;
        padding: 13px 15px;
        border-bottom: 1px solid $copec-another-grey;
        input{
          float: right;
        }
      }
    }
  }
  &closeButton svg {
    fill: $copec-dark-blue;
  }

  &content {
    padding: 0 50px 0 20px;
    margin-bottom: 20px;
    color: $copec-dark-blue;
  }
  &title {
    font-size: 16px;
    font-weight: bold;
    color: $copec-dark-blue;
    margin-bottom: 5px;
    max-width: 290px;
  }
  &subtitle {
    font-size: 14px;
    font-weight: bold;
    color: $copec-grey;
    margin: 0;
  }
}

.save-button{
  background: $copec-dark-blue;
  font-weight: bold;
  padding: 15px 25px;
  color: white;
  margin: 20px 20px 20px 0;
  cursor: pointer;
  border: none;
  border-radius: 25px;
}

.modal-rechazo-container{
  .big-icon{
    width: 120px;
    margin: 50px auto 45px;
    display: block;
  }
  .modal-title{
    color: $copec-dark-blue;
    font-size: 22px;
    font-weight: bold;
    text-align: center;
  }
  .modal-info{
    margin-top: 15px;
    font-size: 14px;
    color: $copec-dark-blue;
    line-height: 1.71;
    text-align: center;
  }
  .rechazo-button{
    width: 220px;
    font-size: 16px;
    margin: 35px auto;
    display: block;
    &.rechazo-button-hollow{
      background: white;
      border: 1px solid $copec-dark-blue;
      color: $copec-dark-blue;
      padding: 14px;
    }
  }
  hr{
    border: none;
    border-bottom: 1px solid #dee4ea;
    margin: 20px 0;
  }
  .modal-title2{
    font-size: 16px;
    color: $copec-dark-blue;
    font-weight: bold;
  }
  .modal-subtitle{
    color: $copec-light-grey;
    font-size: 14px;
    font-weight: bold;
    margin-top: 5px;
  }
  .mail-rechazo{
    text-align: left;
  }
  .custom-button{
    width: 160px;
    display: block;
    margin: 35px auto;
  }
}