.columns{
	display: flex;
	width: 100%;
	.col-left{
		padding: 150px 90px;
		width: 40%;
		background-color: $copec-background-grey;

		.title{
			color: $copec-dark-blue;
			font-weight: bold;
			font-size: 45px;
		}
		.subtitle{
			margin-top: 30px;
			font-size: 25px;
		}
	}
	.col-right{
		width: 60%;
		padding: 50px 0 50px 90px;
		.title{
			font-weight: bold;
			font-size: 25px;
			color: $copec-dark-blue;
		}
		.body{
			margin-top: 30px;
			li{
				margin-top: 30px;
			}

			.note{
				background-color: $copec-background-grey;
				padding: 15px;
				color: $copec-dark-blue;
				border-radius: 10px;
				margin: 30px 20px;
			}
		}
	}
}

.apply-button{
	background: $copec-very-dark-grey;
	display: block;
	margin: 40px auto;
	border: none;
	font-weight: bold;
	font-size: 14px;
	color: white;
	border-radius: 30px;
	padding: 13px 35px;

	&.enabled{
		background: $copec-dark-blue;
	}
}