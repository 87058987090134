@import "./variables";
.processes{
	min-height: 600px;
  .filters{
  	margin-top: 40px;
  }

  .asignar-button{
  	padding: 8px;
  	font-size: 12px;
  	font-weight: bold;
  	width: 124px;
  	color: white;
  	background: $copec-dark-blue;
  	border-radius: 36px;
  	border: none;
    &:disabled{
      background: #cccccc;
      cursor: not-allowed;
    }
  }
}

.button-container-full-width{
	margin: 32px auto;
	text-align: center;
}

.postulante-email{
  max-width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;  
}