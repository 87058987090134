@import "./variables";

.administracion-usuarios-form{
	input{
		padding: 5px 15px;
	}
}

.administracion-usuarios-form-label{
	color: $copec-dark-blue;
	font-weight: bold;
	font-size: 12px;
	margin: 15px 0 5px;
}

.usuarios-section-title{
	font-size: 20px;
	margin-top: 30px;
	font-weight: bold;
	color: $copec-dark-blue;
	margin-bottom: 10px;
}

.users-admin-table{
	max-height: 421px;
	overflow: auto;
}

.user-create-button{
	margin-left: 50px;
}
