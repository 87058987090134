@import "./variables";

textarea:focus, input:focus{
  outline: none;
}

.report {
  border-collapse: separate;
  border-spacing: 0;
  border-style: hidden;
  color: $selected-table;

  .selected{
    background-color: $selected-table !important;
    color: white;
    font-weight: bold;
  }
}

.section-title{
  font-size: 30px;
  font-weight: 700;
  color: $copec-dark-blue;
}

.section-subtitle{
  color: $copec-grey;
  margin-top: 5px;
  margin-bottom: 30px;
}

input, select{
  outline: none;
}

.filter-select{
  width: 192px;
  margin-bottom: 40px;
}

.filter-container{
  float: left;
  margin: 0 20px 0 0;
  .filter-title{
    margin-bottom: 15px;
    font-weight: bold;
    text-align: left;
    color: $copec-dark-blue;
    font-size: 12px;
  }
}

h1{
  font-size: 16px;
  color: $copec-dark-blue;
  font-weight: bold;
  text-align: left;
}

h2{
  font-size: 12px;
  color: $copec-dark-blue;
  font-weight: normal;
  margin: 8px 0;
}

.action-button{
  width: 160px;
  background: $copec-dark-blue;
  color: white;
  font-size: 12px;
  padding: 7px;
  border: none;
  border-radius: 36px;
  font-weight: bold;
  &.hollow{
    border: 1px solid $copec-dark-blue;
    color: $copec-dark-blue;
    background: white;
  }
}

.processes-table{
  .th-data{
    min-width: 110px;
  }
}

.users-admin-table{
  td{
    cursor: default;
    &.users-admin-table-edit-column{
      cursor: pointer;
    }
  }

}

.copec-table-base {
  background: #dee4ea;
  padding: 4px 8px 8px;
  margin-top: 9px;
  border-collapse: collapse;
  display: inline-block;
  border-radius: 6px;

  th{
    color: #fff;
    font-size: 12px;
    text-align: left;
    border: 4px solid #dee4ea;
    &.first{
      border-left: 0;
    }
    &.last{
      border-right: 0;
    }
  }

  .th-data {
    padding: 15px 8px;
    height: 16px;
    background: $copec-dark-blue;
    border-radius: 6px;
    font-size: 12px;
    position: relative;
    .th-text {
      justify-content: space-between;
      .arrows {
        width: 9px;
        position: absolute;
        top: 0;
        right: 8px;
        height: 46px;
        .arrow-up{
          position: absolute;
          top: 15px;
          cursor: pointer;
        }
        .arrow-down{
          position: absolute;
          bottom: 16px;
          cursor: pointer;
        }  
      }
    }
    &--width-30{
      min-width: 20px !important;
      width: 20px !important;
      padding: 15px 8px;
    }
  }

  tbody{
    background: white;
    color: $copec-dark-blue;
    font-size: 12px;
    tr{
      cursor: pointer;
      &:hover{
        font-weight: bold;
      }
    }
    tr:nth-child(even) {
      background-color: #f0f5f8;
    }
    tr:nth-child(odd) {
      background-color: white;
    }

    tr:last-child td:first-child {
      border-bottom-left-radius: 4px;
    }
    tr:last-child td:last-child {
      border-bottom-right-radius: 4px;
    }
    tr:first-child td:first-child {
      border-top-left-radius: 4px;
    }
    tr:first-child td:last-child {
      border-top-right-radius: 4px;
    }

    td{
      padding: 15px 8px;
      &.button-container{
        padding: 0 8px;
      }
    }
  }

  &--no-pointer{
    tbody tr{
      cursor: default;
      td{
        cursor: pointer;
      }
    }
  }
}

.localidades-table{
  tbody{
    tr{
      cursor: default;
      &:hover{
        font-weight: normal;
      }
      .action-column{
        &:hover{
          font-weight: bold;
        }
        cursor: pointer;
      }
    }
  }
}

.choices-separator{
  border: none;
  border-top: 1px solid $copec-another-grey;
  margin: 30px 0;
}

.textarea-counter{
  color: $copec-dark-blue;
  font-size: 12px;
  text-align: right;
}

.custom-button{
  font-weight: bold;
  font-size: 12px;
  padding: 8px 33px;
  color: white;
  cursor: pointer;
  border: none;
  border-radius: 25px;
  &.blue-button{
    background: $copec-dark-blue;
  }
  &.blue-button-hollow{
    background: white;
    padding: 7px 32px;
    color: $copec-dark-blue;
    border: 1px solid $copec-dark-blue;
  }
  &.red-button{
    background: $copec-red;
  }
  &.red-button-hollow{
    background: white;
    padding: 7px 32px;
    color: $copec-red;
    border: 1px solid $copec-red;
  }
  &.choices-button{
    width: 160px;
    padding: 7px 0;
    &:disabled{
      background: $copec-grey-2;
      padding: 8px 0 !important;
      color: white;
      border: none;
      cursor: default;
    }
  }

  &.blue-button:disabled, &.red-button:disabled{
    background: $copec-grey-2;
    padding: 8px 33px;
    color: white;
    border: none;
    cursor: default;
  }

  &.red-button-hollow:disabled, &.blue-button-hollow:disabled{
    background: white;
    color: $copec-grey-2;
    border: 1px solid $copec-grey-2;
    padding: 7px 32px;
    cursor: default;
  }

  &.upload-file{
    padding: 7px 39px;
    margin: 7px 0;
    img{
      float: left;
    }
    span{
      margin-left: 10px;
      float: right;
    }
  }
}

.section{
  color: $copec-dark-blue;
  margin-bottom: 16px;
  width: 100%;
  display: inline-block;
  .data-title-replacement{
    border: none;
    border-bottom: 1px solid $copec-another-grey;
  }
  .description{
    font-size: 12px;
    color: $copec-dark-blue;
    margin-bottom: 18px;
    line-height: 2;
  }
  .section-title{
    color: $copec-light-grey;
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 16px;
    width: 100%; 
    border-bottom: 2px solid $copec-another-grey; 
    line-height: 0.1em;
    margin: 10px 0 20px; 
    span { 
      background: white; 
      padding: 0 10px 0 0; 
    }
    &.data-title{
      text-transform: uppercase;
    }
  }
  .section-data{
    font-size: 12px;
    float: left;
    margin-right: 31px;
    margin-bottom: 16px;
    &.section-data-96{
      width: 96px;
    }
    &.section-data-190{
      width: 190px;
    }
    &.section-data-192{
      width: 192px;
    }
    &.section-data-170{
      width: 170px;
    }
    &.section-data-260{
      width: 260px;
    }
    &.section-data-416{
      width: 416px;
    }
    &.section-data-462{
      width: 462px;
    }
    &.section-data-640{
      width: 640px;
    }
    &.section-data-last{
      margin-right: 0;
    }
    .title{
      width: 100%;
      font-size: 12px;
      margin-bottom: 8px;
    }
    .data{
      background: $copec-input-grey;
      padding: 8px 16px;
      border-radius: 4px;
    }
  }
}

.uploaded-file{
  position: relative;
  background: $copec-input-grey;
  height: 72px;
  width: 304px;
  border-radius: 4px;
  margin-top: 10px;
  .file-type-container{
    border-right: 1px solid $copec-light-grey;
    padding: 6px 20px;
    position: absolute;
    top: 16px;
    height: 28px;
    left: 0;
  }
  .file-info-container{
    position: absolute;
    top: 12px;
    left: 80px;
    color: $copec-dark-blue;
    cursor: pointer;
    width: 150px;
    height: 50px;
    .title{
      font-size: 12px;
      font-weight: normal;
      margin: 3px 0 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 215px;
    }
    .file-type{
      font-size: 14px;
      font-weight: bold;
    }
  }
  .uploaded-file-delete{
    cursor: pointer;
    float: right;
    margin: 27px 20px 0 0;
  }
}

.waiting-parallel-step-alert{
  background: $copec-input-grey;
  margin-top: 24px;
  padding: 12px 16px;
  color: $copec-dark-blue;
  font-size: 12px;
  .red{
    color: $copec-red;
    font-weight: bold;
  }
  &.waiting-parallel-step-alert-extra-margin-bottom{
    margin-bottom: 20px;
  }
}

.bottom-step-number{
  color: $copec-dark-blue;
  font-size: 12px;
  text-align: right;
  margin-top: 88px;
}

.tabla-copec-1{
  font-size: 12px;
  width: 100%;
  background: $copec-another-grey;
  border-radius: 8px;
  border: 8px solid $copec-another-grey;
  border-spacing: 0px;

  th{
    text-align: left;
    color: white;
    background: $copec-dark-blue;
    padding: 16px 5px 16px 24px;
    border: 1px solid $copec-dark-blue;
    &:first-child{
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    &:last-child{
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  .tabla-copec-1-separator{
    height: 8px;
  }

  .tabla-copec-1-tbody-separator{
    height: 1px;
    td{
      .tabla-copec-1-tbody-separator-inner{
        background: $copec-another-grey;
        height: 1px;
        margin: 0 16px;
      }
    }
  
  }

  tbody{
    tr{
      background: white;
      border-bottom: 1px solid green;

      &:first-child{
        td:first-child{
          border-top-left-radius: 8px;
        }
        td:last-child{
          border-top-right-radius: 8px;
        }
      }

      &:last-child{
        td:first-child{
          border-bottom-left-radius: 8px;
        }
        td:last-child{
          border-bottom-right-radius: 8px;
        }
      }
    }
  }

  .tabla-copec-1-input{
    background: $copec-input-grey;
    padding: 8px 16px;
    margin: 16px 24px;
    border-radius: 4px;
    color: $copec-light-grey;
    height: 15px;
  }
}

.tabla-copec-1-row-title{
  margin: 19px 8px 19px 24px;
}

.promedio-ventas-table-subtitles{
  .tabla-copec-1-input{
    margin: 8px 0 0 0;
  }

  .promedio-ventas-table-subtitles-inner{
    margin: 16px 24px;
    .react-datepicker-wrapper{
      input{
        padding: 6px 8px;
        font-size: 12px;
        color: $copec-dark-blue;
      }
    }
  }
}

.promedio-ventas-table-title{
  font-weight: bold;
}

.center{
  text-align: center;
}

/* Sacar flechas de inputs number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.location-active{
  font-weight: bold;
  color: $copec-green;
}

.location-not-active{
  font-weight: bold;
  color: $copec-red;
}

.show-pointer{
  cursor: pointer;
}

.no-pointer{
  cursor: default !important;
}

.editar-icon-processes{
  margin-left: 5px;
}
